@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #262421;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

.cookies-content {
  flex: none !important;
  display: inline;
}

.cookies-container {
  align-items: center !important;
  justify-content: center !important;
}

.main-container {
  max-width: 1440px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 24px 0 40px;
}

.hero-wrapper {
  background-image: url("./img/squared-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.hero {
  display: flex;
  width: 100%;
  gap: 55px;
  max-width: 1040px;
  padding: 100px 0;
  margin: 0 auto;
}

.hero-text,
.hero-img {
  width: 50%;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 474px;
  text-align: left;
  justify-content: center;
}

.hero-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  color: #fff;
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 700;
  line-height: 70.4px;
}

h2 {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  max-width: 514px;
}

.btn-primary {
  display: block;
  padding: 26px 70px 34px;
  border-radius: 16px;
  background: #81b64c;
  box-shadow: 0px -8px 0px 0px #45753c inset;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
  width: max-content;
  cursor: pointer;
}

a.btn-primary:hover,
.btn-primary:hover {
  background: linear-gradient(
      0deg,
      rgba(178, 224, 104, 0.5) 0%,
      rgba(178, 224, 104, 0.5) 100%
    ),
    #81b64c;
  box-shadow: 0px 0px 8px 0px rgba(93, 153, 72, 0.2),
    0px 0px 16px 0px rgba(93, 153, 72, 0.2),
    0px -8px 24px 0px rgba(69, 117, 60, 0.5) inset,
    0px -8px 0px 0px #5d9948 inset;
}

a.btn-primary {
  text-decoration: none;
}

a.btn-primary:hover,
a.btn-primary:visited {
  color: #fff;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px;
  background-color: rgba(0, 0, 0, 0.2);
}

.cards-container {
  display: flex;
  gap: 40px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 200px;
}

.card p {
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.personalities {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  padding-top: 120px;
}

.items-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 213px;
  height: 213px;
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
}

.item img {
  width: auto;
  height: 100%;
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
}

.item img:hover {
  transform: scale(1.1);
}

.progress-bar-grid {
  display: grid;
  margin-bottom: 40px;
  gap: 4px;
}

.progress-bar-piece {
  height: 4px;
  border-radius: 4px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.progress-fill {
  height: 100%;
  background-color: #81b64c;
}

.footer {
  padding: 80px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.logos-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.nav-container {
  display: flex;
  gap: 12px;
}

.nav-item {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  position: relative;
}

.nav-item:not(:last-child)::after {
  content: "|";
  position: absolute;
  width: 4px;
  right: -8px;
}

.quiz {
  width: 100%;
  max-width: 1100px;
  padding: 0 0 84px;
  margin: 0 auto;
}

.answer {
  border-radius: 5px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset;
  color: rgba(255, 255, 255, 0.72);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2em;
  padding: 16px 20px;
  width: 428px;
  cursor: pointer;
  min-height: 97px;
}

.answer:hover {
  background: #ffffff24;
}

.question-container {
  display: flex;
  height: 512px;
  gap: 160px;
  justify-content: space-between;
}

.question {
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* width: 428px; */
  width: 50%;
  justify-content: space-between;
  padding: 86px 0;
}

.question-text {
  color: rgba(255, 255, 255, 0.85);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.question-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 512px;
}

.question-visual img {
  object-fit: contain;
}

.answers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.description-container {
  display: flex;
  width: 100%;
  gap: 40px;
}

.description,
.description-visual {
  width: 50%;
}

.description-visual {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.share-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.share-buttons {
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  gap: 9px;
  justify-content: center;
  padding: 16px;
  visibility: hidden;
  width: fit-content;
}

.share-button {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
}

.share-button.copy-link {
  position: relative;
}

.copy-success {
  position: absolute;
  top: -20px;
  padding: 1px 4px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #fff;
  color: #262421;
}

.share-button-main {
  border: none;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 16px 24px 20px;
}

.share-button-main span {
  font-size: 22px;
}

.celibrity-container img {
  border-radius: 5px;
}

.celibrity {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.opening {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.celibrity-container,
.opening-container {
  display: flex;
  gap: 16px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.celibrity-heading,
.opening-heading {
  color: rgba(255, 255, 255, 0.72);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.celibrity-description,
.opening-description {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.opening-description a {
  color: #fff;
  text-decoration: underline;
}

.opening-description a:hover,
.opening-description a:visited {
  color: #fff;
}

.percents {
  display: flex;
  flex-direction: column;
  gap: 13px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 24px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 10px;
}

.results-heading {
  padding: 20px 0 40px;
}

.description-text {
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labels-container {
  display: flex;
  justify-content: space-between;
}

.label {
  color: rgba(255, 255, 255, 0.72);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.decision-percents {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.percents-bar {
  height: 25px;
  border-radius: 10px;
  width: 100%;
  background: #3d3b39;
  display: flex;
  justify-content: center;
}

.percent-bar-inside {
  position: relative;
  height: 100%;
  width: 96%;
}

.indicator {
  width: 3px;
  height: 100%;
  background: #81b64d;
  position: absolute;
}

.results-title {
  color: rgba(255, 255, 255, 0.72);
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 16px;
  display: flex;
}

.cta-button {
  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.visible {
  visibility: visible;
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 1440px) {
  .quiz {
    padding: 0 40px 84px;
  }

  .question-container {
    width: 100%;
    gap: 48px;
  }

  .question {
    padding: 40px 0;
  }

  .question-visual,
  .question {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .cookies-content {
    flex: 1 !important;
    display: flex;
    margin: 16px 0px 16px 13px !important;
  }

  .hero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding-bottom: 64px;
    padding-top: 64px;
  }

  .hero-wrapper {
    background: none;
  }

  .hero-text,
  .hero-img {
    width: 100%;
  }

  .description,
  .description-visual {
    width: 100%;
  }

  .items-grid {
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  h2 {
    text-align: center;
    color: #fff;
    font-size: 31px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
  }

  .hero-text {
    align-items: center;
    max-width: unset;
    padding: 80px 24px 0px;
  }

  .cta-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
  }

  .question-container {
    height: auto;
    flex-direction: column-reverse;
    align-items: center;
    gap: 68px;
  }

  .results {
    padding: 48px 24px 0px;
  }

  .results-heading {
    padding: 8px 0 48px;
  }

  .label {
    text-transform: capitalize;
  }

  .info {
    gap: 48px;
    padding: 64px 24px;
  }

  .personalities {
    padding: 48px 24px 48px;
    gap: 48px;
  }

  .cta-button {
    margin-bottom: 64px;
  }

  .quiz {
    padding: 24px;
  }

  .cards-container {
    flex-direction: column;
    gap: 48px;
  }

  .card {
    gap: 16px;
  }

  .book-img {
    width: 108px;
    height: 88px;
  }

  .siluet-img {
    width: 88px;
    height: 88px;
  }

  .queen-img {
    width: 95px;
    height: 88px;
  }

  .description-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .answers-container {
    gap: 8px;
  }

  .question-visual {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 512px;
  }

  .question {
    padding-top: 0;
    padding-bottom: 0;
  }

  .answer {
    width: 100%;
    min-height: 90px;
    max-height: fit-content;
  }

  .btn-primary {
    width: 100%;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    padding: 16px 8px 20px;
    box-shadow: inset 0 -5px 0 0 #45753c;
  }

  .share-container {
    flex-direction: column-reverse;
  }

  .description.mobile-only {
    padding: 0;
    background: none;
  }

  .description.mobile-only .description-text {
    padding: 24px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 10px;
  }

  .description.mobile-only .share-buttons {
    visibility: visible;
    display: none;
  }

  .description.mobile-only .share-buttons.block {
    display: flex;
    width: 100%;
  }

  .progress-bar-grid {
    margin-bottom: 24px;
  }

  .logos-container {
    gap: 6px;
  }

  .footer {
    gap: 24px;
  }

  .mobile-only {
    display: flex;
  }

  .desktop-only {
    display: none;
  }
}

@media screen and (max-width: 756px) {
  .items-grid {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
  }

  .hero {
    padding-top: 0;
  }
}

@media screen and (max-width: 640px) {
  h1 {
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
  }

  .header {
    padding: 32px 24px 0px;
  }

  .chesscom-logo {
    display: none;
  }

  .item {
    width: 165px;
    height: 165px;
  }

  .hero-img {
    overflow: hidden;
  }

  .hero-img img {
    width: 440px;
    height: 423px;
  }

  .logos-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .question {
    width: 100%;
    gap: 24px;
    justify-content: space-between;
    height: 100%;
  }

  .question-visual {
    width: 256px;
    height: 256px;
  }

  .question-text {
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    /* min-height: 78px; */
  }

  .answer span {
    color: rgba(255, 255, 255, 0.72);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2em;
  }

  .question-container {
    gap: 16px;
    height: 100%;
  }

  .question-visual img {
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 389px) {
  .items-grid {
    grid-template-columns: 1fr;
  }

  .nav-container {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
